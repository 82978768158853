<template>
  <a-drawer class="activity-add" title="社群活动相关" @close="onClose" :width="720" :visible="addVisiable" :maskClosable="false">

    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="活动名称" prop="title" v-bind="formItemLayout">
        <a-input v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item label="举办社群" prop="communityId" v-bind="formItemLayout">
        <a-select v-model="form.communityId">
          <a-select-option :value="item.communityId" v-for="item in communityList" :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="开始时间" prop="beginTime" v-bind="formItemLayout" >
        <a-date-picker show-time format="YYYY-MM-DD HH:mm" v-model="form.beginTime" />
      </a-form-model-item>
      <a-form-model-item label="活动对象" prop="rules">
        <user-rule-creator
          @valueChange="rulesValueChange"
          ref="userRuleCreator"
        />
      </a-form-model-item>
      <!-- <a-form-model-item label="参加人数" prop="nowParticipants" v-bind="formItemLayout">
        <a-input-number v-model="form.nowParticipants" :min="0"/>&nbsp;人
      </a-form-model-item> -->
      <a-form-model-item label="限制人数" prop="maxParticipants" v-bind="formItemLayout">
        <a-input-number v-model="form.maxParticipants" :min="0"/>&nbsp;人
      </a-form-model-item>
      <a-form-model-item label="封面图" prop="image" v-bind="formItemLayout">
        <UploadImgs :path="IMAGE_ACTIVITY" :file-list="fileList" :limit="1" @_change="handleUploadImgChange"></UploadImgs>
      </a-form-model-item>
      <a-form-model-item label="活动附件" v-bind="formItemLayout" >
        <UploadEnclosure :path="PDF_ACTIVITY" :enclosure-list="enclosureList" @_change="handleUploadEnclosureChange"></UploadEnclosure>
      </a-form-model-item>
      <a-form-model-item label="活动内容" prop="content" v-bind="formItemLayout">
        <a-textarea placeholder="活动内容" v-model="form.content" :rows="10" />
      </a-form-model-item>
      <a-form-model-item label="活动新闻" v-bind="formItemLayout" >
        <editor name="news" :value.sync="form.news" :height="300" @update-value="updateValue"></editor>
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
// import moment from "moment"
import {PDF_ACTIVITY, IMAGE_ACTIVITY} from "../../../utils/upload-path-map"
import UploadImgs from "../../../components/upload-imgs/UploadImgs"
import UploadEnclosure from "../../../components/upload-enclosure/UploadEnclosure"
import Editor from "../../../components/editor/editor"
import UserRuleCreator from "../../business-common/UserRuleCreator";

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}

export default {
  name: "ActivityAdd",
  components: {UploadEnclosure, UploadImgs, Editor, UserRuleCreator},
  props: {
    addVisiable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formItemLayout,
      PDF_ACTIVITY,
      IMAGE_ACTIVITY,
      enclosureList: [],
      fileList: [],
      communityList: [],
      targetGroups: [],
      form: {
        id: null,
        title: "",
        communityId: "",
        userList: null,
        listName: "",
        nowParticipants: null,
        enclosure: undefined,
        content: "",
        news: "",
        rules: undefined,
        beginTime: undefined
      },
      rules: {
        title: [
          { required: true, message: '活动名称不能为空', trigger: 'blur' }
        ],
        communityId: [
          { required: true, message: '举办社群不能为空', trigger: 'change' }
        ],
        beginTime: [
          { required: true, message: '开始时间不能为空', trigger: 'change' }
        ],
        listName: [
          { required: true, message: '对象不能为空', trigger: 'blur' }
        ],
        userList: [
          { required: true, message: '用户组不能为空', trigger: 'blur' }
        ],
        maxParticipants: [
          { required: true, message: '限制人数不能为空', trigger: 'blur' }
        ],
        rules: [{ required: true, message: "请指定规则", trigger: "blur" }],
        image: [
          { required: true, message: '封面不能为空', trigger: 'change' }
        ],
        content: [
          { required: true, message: '活动内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.$get("group/list?groupType=4").then(r => {
      this.targetGroups = r.data || [];
    });
    this.$get("community/list").then(r => {
      this.communityList = r.data || [];
    });
  },
  methods: {
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.reset();
      this.$emit("close");
    },
    rulesValueChange(s) {
      this.form.rules = s;
    },
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
      this.enclosureList = [];
      this.fileList = [];
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let beginTime = this.form.beginTime?this.form.beginTime.format("YYYY-MM-DD HH:mm"):null;
          this.$post("activity", {...this.form, beginTime}).then(r => {
            // console.log("添加社群活动相关", r.data);
            this.reset();
            this.$emit("success");
          })
        } else {
          return false;
        }
      });
    },
    handleTargetChange(value) {
      let group = this.targetGroups.find(item => item.groupId == value);
      console.log("对象==>", group);
      this.form.userList = group.userIdList;
    },
    updateValue(val, key='') {
      // console.log("key: ", key, "val: ", val);
      this.form[key] = val;
    },
    handleUploadImgChange(fileList, urls) {
      console.log("上传图片组件传过来的值", urls);
      this.form.image = urls.length?urls[0]['filePath']:'';
      this.fileList = fileList;
    },
    handleUploadEnclosureChange(fileList, urls) {
      // console.log("上传附件的返回值===>", list, urls);
      urls = urls.map(item => {return {url: item.filePath}});
      this.form.enclosure = JSON.stringify(urls);
      this.enclosureList = fileList;
    }
  }
}
</script>

<style lang="less">
// .w-e-toolbar {
//   z-index: 0 !important;
// }
</style>